.line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin-left: 0px;
  margin-top: 30px;
  margin-right: 150px;
  margin-bottom: 35px;
}

.line span {
  background: #f9fafc;
  padding: 0 10px;
}
