.like-icon {
  cursor: pointer;
}
.like-icon.active {
  fill: #378fe9;
}
.emotions-section {
  display: flex;
}
.emotions-section svg {
  width: 25px;
  opacity: 0.4;
  cursor: pointer;
  margin-right: 5px;
}
.emotions-section svg.active {
  opacity: 1;
}
.emotions-section svg:hover {
  width: 35px;
  height: 35px;
  transition: 0.15s ease;
  opacity: 1;
}
.text-none {
  text-decoration: none;
}
.flex {
  display: flex;
  align-items: center;
}
.li-icon {
  width: 20px;
  margin-right: 10px;
  cursor: pointer;
}
