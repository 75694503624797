.tableContainer {
  height: calc(94vh - 140px);
  overflow-y: auto;
  position: relative;
  border-radius: 0 0 5px 5px;
}

.tableContainer::-webkit-scrollbar {
  width: 10px;
}

.tableContainer::-webkit-scrollbar-track {
  background: #f4f5f9;
}

.tableContainer::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 25px;
  height: 25%;
}

.table {
  width: 100%;
  border-spacing: 0;
}

.tableHeader {
  background: #f4f5f9;
  color: #168ef9;
  padding: 0 10px;
  position: sticky;
  border-radius: 5px;
  border: none;
  top: 0;
  z-index: 2;
}
.lime-wrapper {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.lime-wrapper:hover {
  -webkit-line-clamp: unset;
  cursor: pointer;
}
th {
  padding: 5px 10px;
  font-size: 14px;

  text-align: start;
  font-weight: 700;
}

tbody {
  background: #fff;
  border-radius: 5px;
}

td {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 0;
  border-right: 0;
  border-top: 0;
  font-size: 14px;
  min-width: 50px;
}

.actionsCell {
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}

.checkboxCell {
  padding: 15px;
  min-width: 0 !important;
}

.fetchInfoCell {
  min-width: 70px;
  padding-left: 18px;
}

.connectedAtCell {
  min-width: 100px;
}

.locationCell {
  min-width: 130px;
}

.contactInfoCell {
  white-space: pre-line;
}

.searchFilterContainer {
  display: grid;
  grid-template-columns: 200px 200px 200px;
  grid-gap: 20px;
}

.filters {
  padding: 10px;
  margin-top: 20px;
}

.hide {
  display: none;
}

.tableLoadingContainer {
  position: absolute;
  top: 0;
  z-index: 300;
  background: rgba(0, 0, 0, 0.04);
  width: 100%;
  height: 100%;
}
.item {
  display: flex;
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  border-bottom: 1px solid #cbcbcb;
}
.avatar-img {
  width: 58px;
  border-radius: 50px;
  height: 58px;
  cursor: pointer;
  position: relative;
  float: 'left';
  border: 1px solid #cbcbcb;
}
.u-info {
  margin-left: 15px;
  margin-right: 25px;
  width: 200px;
  flex-shrink: 0;
}

.profilePictureCell {
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}

.u-msg {
  width: 100%;
}

.contact-name {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 2px;
}

.contact-title {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 5px;
}

.notification-date {
  font-size: 13px;
}

.u-msg p {
  font-size: 14px;
}
.notif-icon svg {
  margin-right: 5px;
}
.u-msg .headline {
  flex-shrink: 3;
  width: 800px;
  /* margin-bottom: 8px; */
  /* width: 50rem; */
}
.u-msg input {
  width: 98%;
  padding: 5px;
  /* margin-left: 10px; */
}

.disable-input {
  background-color: #dfdddd;
  border: 1px solid gray;
  pointer-events: none;
}
.item .actions-section {
  display: flex;
  margin-top: 5px;
}
.actions-section div.ub-box-szg_border-box {
  /* flex-shrink: 0; */
  width: 90%;
  margin: 0px;
}
.actions-section svg {
  margin: auto;
  transform: scale(1.3);
}
.greenBack {
  background-color: rgba(0, 128, 0, 0.11);
}

* {
  font-family: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  color: #101840;
}
.justifier {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
