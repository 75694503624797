.loadingContainer {
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  place-items: center;
  text-align: center;
  white-space: pre-line;
}

.loadingContainer > .loadingIcon {
  animation-name: grow;
  animation-duration: 1.3s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.loadingContainer > .loadingIcon > img {
  width: 50px;
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(2);
  }
}
